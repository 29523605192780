import { extendTheme } from '@chakra-ui/react'

const colors = {
  green: '#0FAE6F',
  blue: '#1E93FF',
  red: '#DD5E5E',
  yellow: '#FFE500',
  bg: 'linear-gradient(180deg, #086B45 0%, #031E10 62.5%)',
  dark: '#141414',
  dark60: 'rgba(20, 20, 20, 0.60)',
  dark90: 'rgba(20, 20, 20, 0.90)',
  
  white: '#FFFFFF',
  white5: 'rgba(255, 255, 255, 0.05)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white80: 'rgba(255, 255, 255, 0.8)'
}


const theme = extendTheme({ 
  colors,
  components: {
    Button: {
      baseStyle: {
        fontSize: '16px',
        fontWeight: '700',
        borderRadius: '32px',
        color: 'white !important',
        textAlign: 'center',
        _focus: {
          boxShadow: 'none'
        }
      },
      sizes: {
        md: {
          width: '167px',
          height: '60px',
        },
      },
      variants: {
        primary: {
          background: 'var(--chakra-colors-green)',
          _disabled: {
            background: 'var(--chakra-colors-white5)',
          }
        },
        secondary: {
          background: 'rgba(255, 255, 255, 0.1)'
        }
      },
      defaultProps: {
        size: 'md',
        variant: 'primary'
      }
    },
    Text: {
      baseStyle: {
        color: 'white'
      },
    
      sizes: {
        title: {
          fontSize: '32px',
          fontWeight: '800',
          lineHeight: '40px',
          textAlign: 'center'
        },
        title1: {
          fontSize: '24px',
          fontWeight: '800',
          lineHeight: '30px',
          textAlign: 'center'
        },
        body: {
          fontWeight: '700',
          lineHeight: '1.4em',
          fontSize: '16px'
        }
      },
      
      defaultProps: {
        size: 'body',
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '14px',
        mb: '10px'
      }
    },
    Input: {
      baseStyle: {
        
        field: {
          fontSize: '16px',
          fontWeight: '700',
          height: '50px',
          borderRadius: '15px',
          background: 'white !important',
          
          _invalid: {
            
          }
        },
        _placeholder: {
          
        },
        _disabled: {
          
        }
      },
      defaultProps: {
     
      }
    }
  }
})

export default theme