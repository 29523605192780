import { Box, Flex, Text } from '@chakra-ui/react'
import Prize from 'components/prize/Prize'
import { memo } from 'react'


const GamePrize = memo(({ prize }) => (
  <Box flexShrink="0">
    
      <Box flexBasis="100%" pt="15px" pl="15px">
        <Text 
          color="#FFFFFFCC"
          fontSize="14px"
          fontWeight="600"
          textTransform="uppercase"
          lineHeight="17px"
          textAlign="center"
        >Tonight’s prize</Text>
      </Box>
      <Prize prize={prize } mininal />
  </Box>
))

export default GamePrize