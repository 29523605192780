import { Box, SimpleGrid } from '@chakra-ui/react'
import CorrectState from 'components/game/CorrectState'
import IncorrectState from 'components/game/IncorrectState'
import LoseState from 'components/game/LoseState'
import PostedState from 'components/game/PostedState'
import WaitingState from 'components/game/WaitingState'
import WinState from 'components/game/WinState'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router'
import { useGameSettingsQuery } from 'store/services/api'
import { selectUser, setUserLives } from 'store/slices/authSlice'
import { selectSelectedAnswer, selectGameState, setGameState, setSelectedAnswer } from 'store/slices/gameSlice'

import Onboarding from 'components/Onboarding'
import NextGameDate from 'components/game/NextGameDate'
import { useGameGatewayMutation } from 'store/services/users'
import Game from 'components/game/Game'
import GamePrize from 'components/game/GamePrize'
import Chat from 'components/chat/Chat'

const mapToComponent = {
  posted: PostedState,
  waiting: WaitingState,
  correct: CorrectState,
  incorrect: IncorrectState,
  win: WinState,
  lose: LoseState
}

const GamePage = () => {
  const user = useSelector(selectUser)
  const data = useSelector(selectGameState)
  const selectedAnswer = useSelector(selectSelectedAnswer)
  const [prize, setPrize] = useState(null)
  const [connectionAllowed, setConnectionAllowed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [checkGameStatus] = useGameGatewayMutation()

  const socketRef = useRef(null)
  const deferredPayload = useRef(null)

  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(
    () => {
      const userLives = data?.user_lives
      dispatch(setUserLives(userLives ?? null))
      return () => {
        dispatch(setUserLives(null))
      }
    },
    [data]
  )

  useEffect(
    () => {
      const onLoad = async () => {
        const { error, data } = await checkGameStatus()
        if (error) {
          if ([400, 404].includes(error?.status)) {
            setErrorMessage(error?.data?.error)
          } else {
            navigate('/')
          }
        } else {
          setConnectionAllowed(true)
        }
      }
      onLoad()
    },
    []
  )

  useEffect(() => {
    if (!connectionAllowed) return
    let timer
    let terminated = false
    if (!user) {
      terminated = true
      dispatch(setGameState(undefined))
      socketRef.current?.close()
      return
    }

    const initializeWebSocket = () => {
      timer = null
      if (terminated) {
        socketRef.current?.close()
        return
      }
      
      const wsBaseUrl = `${process.env.REACT_APP_WEBSOCKET_ENDPOINT || 'wss://api.solosurvivor.co/ws/'}game/`
      socketRef.current = new WebSocket(wsBaseUrl)      
      socketRef.current.onmessage = event => {
        const data = JSON.parse(event.data)
        dispatch(setGameState(data))
      }
      socketRef.current.onopen = () => {
        if (deferredPayload.current && socketRef.current) {
          socketRef.current.send(deferredPayload.current)
          deferredPayload.current = null
        }
      }
      socketRef.current.onclose = (event) => {
        console.log('connection close:', event?.reason)
        timer = setTimeout(initializeWebSocket, 2000)
        socketRef.current = null
      }
    }

    initializeWebSocket()

    return () => {
      terminated = true
      socketRef.current?.close()
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
    }
  }, [connectionAllowed])

  const { data: settings, isLoading: isSettingsLoading  } = useGameSettingsQuery()

  const questionId  = data?.active_question?.id

  const onSelect = useCallback(
    answerId => {
      const payload = JSON.stringify({ data: { type: 'answer', answer_id: answerId, question_id: questionId }})
      dispatch(setSelectedAnswer(payload))
      
    }, 
    [questionId]
  )

  const Component = useMemo(
    () => data ? mapToComponent[data.state] : null,
    [data]
  )

  const state = useMemo(
    () => {
      if (!data) return undefined
      
      const activeQuestion = data.active_question
      const userAnswers = data.user_answers.map(a => ({ ...a, text: a.answer }))
      const usersInCurrentRoundCount = data.users_in_current_round_count
      return {
        ...data,
        usersInCurrentRoundCount,
        activeQuestion: {
          ...activeQuestion,
          text: activeQuestion.question,
          answers: activeQuestion.answers.map(a => ({ ...a, text: a.answer }))
        },
        userAnswers
      }
    },
    [data]
  ) 

  useEffect(
    () => {
      if (data?.id) {
        setPrize(data.prize)
      } else {
        setPrize(null)
      }
    },
    [data?.id]
  )

  const { userAnswers, activeQuestion } = state || {}
  const isResult = ['win', 'lose'].includes(data?.state)

  const contestDate = useMemo(() => {
    if (!settings?.schedule) return null
    return settings.schedule
      .map(s => dayjs(s.date))
      .filter(date => date.isAfter(dayjs()))[0]
  }, [settings])

  useEffect(
    () => {
      if (!state) return
      if (!state.is_in_the_contest) {
        document.body.classList.add('spectator-mode')
        return () => {
          document.body.classList.remove('spectator-mode')
        }
      }
    },
    [state]
  )
  const [displayOnboarding, setDisplayOnboarding] = useState(false)

  useEffect(
    () => {
      const displayed = localStorage.getItem('onboarding', false)
      if (!displayed) {
        setDisplayOnboarding(true)
      }
    },
    []
  )


  useEffect(
    () => {
      if (!user) return
      if (selectedAnswer) {
        if (socketRef.current?.readyState === 1) {
          try {
            socketRef.current.send(selectedAnswer)
            deferredPayload.current = null
            dispatch(setSelectedAnswer(undefined))
          } catch (err) {
            console.log(err)
          }
        } else {
          deferredPayload.current = selectedAnswer
        }
      } else {
        deferredPayload.current = null
      }
    },
    [selectedAnswer, user]
  )

  
  if (isSettingsLoading) return null
  
  if (!user) return <Navigate to="/" />

  if (displayOnboarding){
    return <Onboarding
      onClose={() => {
        setDisplayOnboarding(false)
      }} 
    />
  }
  
  if (connectionAllowed) {
    return (
      <>
        <SimpleGrid
          column="1"
          gridTemplateRows={`${state ? '1fr ' : (contestDate ? '1fr ' : '') } ${prize ? '300px' : ''}`}
          minH="100%"
          pb="100px"
        >
          
          {state ? (
            <Game 
              finished={isResult} 
              Component={Component} 
              onSelect={onSelect} 
              state={state} 
              activeQuestion={activeQuestion} 
              userAnswers={userAnswers}
            />
          ) : (contestDate && <NextGameDate date={contestDate.tz('America/New_York')} />)}
      
          {prize && (
            <GamePrize prize={prize} />
          )}
        
        </SimpleGrid>
        {state && <Chat />}
      </>
    )
  } else {
    return (
      <Box
        minH="100%"
        position="relative"
      >
        <Box 
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          left="0"
          right="0"
          textAlign="center"
          fontSize="20px"
        >
          {errorMessage}
        </Box>
      </Box>
    )
  }
}


export default GamePage;