import { useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import Prize from 'components/prize/Prize'
import PrizesTabs from 'components/prize/PrizesTabs'
import { useClaimedPrizesQuery, useGameSettingsQuery } from 'store/services/api'


const PrizesPage = () => {
  const [index, setIndex] = useState(0)
  const { data: settings, isLoading: isSettingsLoading  } = useGameSettingsQuery()
  const { data: claimedPrizes } = useClaimedPrizesQuery()

  if (isSettingsLoading) return null

  const elements = index === 0 ? settings.schedule : claimedPrizes

  return (
    <Box>
      <Text size="title1">
        Prizes
      </Text>
      <PrizesTabs index={index} onChange={setIndex} />
      <Flex direction="column" gap="40px" mt="30px">
        {elements && elements.map((prize, i) => (
          <Prize key={`${prize.day_of_week}_${index}_${i}`} prize={prize} />
        ))}
      </Flex>
    </Box>
  )
}

export default PrizesPage