const routes = {
   home: '/',
   rules: '/rules',
   terms: '/terms',
   leaderboard: '/leaderboard',
   signin: '/login',
   signup: '/signup',
   prizes: '/prizes',
   game: '/game',
   settings: '/settings',
   resetPassword: '/reset-password',
   resetPasswordConfirm: '/password/reset/confirm/:uid/:token',
   result: '/result',
   changePassword: '/change-password'
}

export default routes
