import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { CSSReset, ChakraProvider } from '@chakra-ui/react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isBetween from 'dayjs/plugin/isBetween'
import updateLocale from 'dayjs/plugin/updateLocale'
import advancedFormat from  'dayjs/plugin/advancedFormat'
import App from './App'
import { store } from './store'
import theme from './theme'
import './style.css'


dayjs.extend(utc)
dayjs.extend(duration);
dayjs.extend(timezone)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isBetween)
dayjs.extend(updateLocale)
dayjs.extend(advancedFormat)
dayjs.tz.setDefault("America/New_York")
dayjs.updateLocale({
  weekStart: 1
})


if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      if (registration.active.scriptURL.includes("sw.js")) {
        registration.unregister();
        console.log('unregistered sw.js')
      }
    } 
});
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <CSSReset />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>  
  </Provider>

)

// reportWebVitals()
