import { Box, keyframes } from '@chakra-ui/react'
import { ReactComponent as HeartIcon } from 'icons/heart.svg'
import { useCallback, useEffect } from 'react'
import { useGetBackToContestMutation } from 'store/services/users'


const shadowAnimation = keyframes`
  0% {
    box-shadow: 0px 0px 40px 0px transparent;
  }
  50% {
    box-shadow: 0px 0px 40px 0px #0FAE6F;
  }
  100% {
    box-shadow: 0px 0px 40px 0px transparent;
  }
`;

const WantToContinueButton = ({ userLives, hidden }) => {
  const [getBackToContest, { isLoading, isSuccess, reset }] = useGetBackToContestMutation()
  const isDisabled = isLoading || isSuccess
  useEffect(
    () => {
      reset()
    },
    [userLives, hidden]
  )
  const onClick = useCallback(
    async () => {
      if (hidden || isDisabled) {
        return
      }
      try {
        const res = await getBackToContest()
        if (res.error) {
          console.error(res.error)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [hidden, isDisabled],
  )
  return (
    <Box
      onClick={onClick}
      height="36px"
      bg="#0FAE6F"
      color="white"
      display="flex"
      alignItems="center"
      width="fit-content"
      pl="16px"
      pr="12px"
      fontSize="14px"
      fontWeight="700"
      justifyContent="center"
      gap="5px"
      borderRadius="20px"
      animation={`${shadowAnimation} 2s ease-in-out infinite`}
      opacity={!hidden ? '1' : '0'}
      cursor={!hidden ? 'pointer' : 'none'}    
    >
      Want to continue?
      <Box
        display="flex"
        alignItems="center"
      >
        <HeartIcon /> {userLives}
      </Box>
    </Box>
  )
}

export default WantToContinueButton