import axios from 'axios'

import { createApi } from '@reduxjs/toolkit/query/react'

const baseURL = process.env.REACT_APP_API_ENDPOINT || 'https://api.solosurvivor.co'

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
})

const baseQuery = async ({ body, ...args }, { signal }) => {

  try {
    const result = await axiosInstance({ data: body, signal, ...args })
    return { data: result.data }
  } catch (axiosError) {
    const err = axiosError
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    }
  }
}

export const api = createApi({
  baseQuery,
  endpoints: build => ({
    weeklyPrizes: build.query({
      query: () => {
        return {
          url: `api/v1/weekly_prizes/`,
        }
      },
      transformResponse: (records) => {
        const data = records || []
        data.sort((a, b) => a.date.localeCompare(b.date))
        return data
      },
    }),
    claimedPrizes: build.query({
      query: () => {
        return {
          url: `api/v1/claimed_prizes/`,
        }
      },
      transformResponse: (records) => {
        const data = records.map(r => ({ ...r.prize, email: r.email, date: r.date_of_winning }))
        data.sort((a, b) => b.date.localeCompare(a.date))
        return data
      },
    }),
    leaderboardList: build.query({
      query: (date) => {
        return {
          url: `api/v1/leaderboard/?date=${date.format('YYYY-MM-DD')}`,
        }
      },
    }),
    gameSettings: build.query({
      query: () => {
        return {
          url: 'api/v1/game_settings/',
        }
      },
    }),
  }),
})


export const {
  useClaimedPrizesQuery,
  useLazyClaimedPrizesQuery,
  useLazyLeaderboardListQuery,
  useLazyWeeklyPrizesQuery,
  useLeaderboardListQuery,
  useWeeklyPrizesQuery,
  useGameSettingsQuery,
} = api