import { Box, Text } from '@chakra-ui/react'
import Preloader from 'components/Preloader'
import Rules from 'components/Rules'
import React from 'react'
import { useGameSettingsQuery } from 'store/services/api'


const RulesPage = () => {
  const { data, isLoading } = useGameSettingsQuery()
  
  if (isLoading) return <Preloader />
  
  const rules = data.rules.common_rules
  
  return (
    <Box>
      <Text size="title">Game Rules</Text>
      <Rules rules={rules} />
    </Box>
  )
}

export default RulesPage