import { Box, Button, Center, FormLabel, Text } from '@chakra-ui/react'
import Input from 'components/form/Input'
import routes from 'constants/routes'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'
import { selectUser } from 'store/slices/authSlice'
import { match_email, required } from 'utils/validation'
import { useUsersCreateMutation } from 'store/services/users'
import Error from 'components/form/Error'


const SignUpPage = () => {
  const user = useSelector(selectUser)
  const [params] = useSearchParams()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [_, setErrors] = useState({})
  const [sent, setSent] = useState(false)
  const [create] = useUsersCreateMutation()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [touched, setTouched] = useState({})
  
  const returnUrl = params && params.get('redirect')

  if (user) {
    return <Navigate to={returnUrl || routes.game} />
  }

  const onSubmit = async e => {
    e.preventDefault()
    setErrors({})
    setIsSubmiting(true)
    try {
      await create({ email: email.toLowerCase(), password }).unwrap()

      setSent(true)
    } catch (error) {
      if (error.status === 400) {
        const errors = Object.keys(error.data).reduce((acc, key) => {
          acc[key] = error.data[key][0]
          return acc
        }, {})
        setErrors(errors)
      }
    }
    setIsSubmiting(false)
  }

  if (sent) {
    return (
      <Box mt="150px">
        <Text size="title1">Thanks for signing up! Verify your email</Text>
        <Text size="body"  color="var(--chakra-colors-white80)" textAlign="center" mt="20px" lineHeight="22px">
        An activation link has been sent to <Box as="span" color="var(--chakra-colors-green)">{email}</Box><br /><br />
          Please click on the activation link to activate your account.
        </Text>
      </Box>
    )
  }
  
  return (
    <Box>
      <Text
        size="title"
      >
        Sign Up
      </Text>
      
      <Box mt="30px" as="form" onSubmit={onSubmit}>
        <Box>
          <FormLabel>Email</FormLabel>
          <Input 
            name="email"
            type="email"
            placeholder="Enter a valid email address"
            value={email}
            onChange={e => {
              setTouched({...touched, email: true})
              setEmail(e.target.value.trim())
            }}
          />
          
          {touched.email && match_email(email) && (
            <Error mt="10px">Please provide a valid email address</Error>
          )}
        </Box>
        <Box mt="20px">
          <FormLabel>Password</FormLabel>
          <Input 
            name="password"
            type="password"
            placeholder="Create a password"
            value={password}
            onChange={e => {
              setPassword(e.target.value.trim())
              setTouched({...touched, password: true})
            }}
          />
          {touched.password && required(password) && (
            <Error mt="10px">It’s required</Error>
          )}
        </Box>
        <Text
          fontSize="12px"
          lineHeight="16px"
          align="center"
          mt="20px"
        >
          By signing up or continuing to use the Services, I agree to the <a href="">Terms of Use</a> and <a href="">Privacy Policy</a>. I confirm that I am of legal age, 18 years or older, and a US resident.
        </Text>
        <Center mt="30px">
          <Button 
            type="submit" 
            isDisabled={isSubmiting || match_email(email) || password.length === 0} 
          >Sign Up</Button>  
        </Center>

        <Box 
          mt="40px"
          color="var(--chakra-colors-white80)"
          textAlign="center"
          fontSize="16px"
          fontWeight="700"
        >
          Have an account? <Link to={routes.signin}>Log In</Link>
        </Box>
              
      </Box>
            
       
        
      
    </Box>
  )
}

export default SignUpPage