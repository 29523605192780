import { api } from './api';

export const usersApi = api.injectEndpoints({
  endpoints: builder => ({
    usersRetrieve: builder.query({
      query: () => {
        return {
          url: `auth/users/me/`,
        };
      },
    }),
    userLogin: builder.mutation({
      query: (body) => ({
        url: `login/`,
        method: 'POST',
        body,
      }),
    }),
 
    resetPassword: builder.mutation({
      query: (email) => ({
        url: `auth/users/reset_password/`,
        method: 'POST',
        body: { email },
      }),
    }),
    resetPasswordConfirm: builder.mutation({
      query: (body) => ({
        url: `auth/users/reset_password_confirm/`,
        method: 'POST',
        body,
      }),
    }),

    changePassword: builder.mutation({
      query: (body) => ({
        url: `change-password/`,
        method: 'POST',
        body,
      }),
    }),
    
    usersCreate: builder.mutation({
      query: (body) => ({
        url: `auth/users/`,
        method: 'POST',
        body,
      }),
    }),

    logout: builder.mutation({
      query: () => ({
        url: `logout/`,
        method: 'POST',
      }),
    }),
    gameGateway: builder.mutation({
      query: () => ({
        url: `api/v1/game_gateway/`,
        method: 'POST',
      }),
    }),
    getBackToContest: builder.mutation({
      query: () => ({
        url: `game/get_back_to_contest/`,
        method: 'POST',
      }),
    }),
    
  }),
});

export const { 
  useLazyUsersRetrieveQuery, 
  useUserLoginMutation,
  useClaimedPrizesQuery,
  useUsersCreateMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation,
  useChangePasswordMutation,
  useLogoutMutation,
  useGameGatewayMutation,
  useGetBackToContestMutation,
} = usersApi;
