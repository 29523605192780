export const CARDS_SHOW_COUNT = 5;
export const CARDS_SELECT_COUNT = 3;

export const ANIMATION_CLASSES = {
  enter: 'enter',
  enterActive: 'active-enter',
  enterDone: 'done-enter',
  exit: 'exit',
  exitActive: 'active-exit',
  exitDone: 'done-exit',
};



export const DAY_OF_WEEK = [
  'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
]


export const USER_EMAIL = '5_CARDS_DRAW_EMAIL'