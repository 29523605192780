import { Box, Flex } from '@chakra-ui/react';
import { useMemo } from 'react';


const NextGameDate = ({ date }) => {
  const time = date.format('h:mma [ET]')
  
  const day = useMemo(
    () => {
      if (date.isToday()) {
        return 'tonight'
      } else if (date.isTomorrow()) {
        return 'tomorrow';
      } else {
        return date.format('MMM Do')
      }
    },
    [date.isToday(), date.isTomorrow()]
  )
  return (
    <Box position="relative">
      <Flex 
        width="100%" 
        height="100%" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center"
      >
        <Box
          color="var(--chakra-colors-yellow)"
          fontSize="18px"
          fontWeight="700"
        >Stay tuned</Box>
        <Box 
          mt="10px"
          fontSize="24px"
          fontWeight="800"
          lineHeight="30px"
          textAlign="center"
          px="40px"
        >
          Contest starts {day} at {time}
        </Box>
      </Flex>
    </Box>
  )
}

export default NextGameDate
