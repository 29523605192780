import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@chakra-ui/react'
import { selectGameState } from 'store/slices/gameSlice'


const Title = () => {  
  const data = useSelector(selectGameState)
  const [timeDiff, setTimeDiff] = useState()

  const expDate = useMemo(
    () => dayjs.utc(data?.question_expiration_time), 
    [data?.question_expiration_time]
  )

  useEffect(
    () => {
      setTimeDiff(dayjs.duration(expDate.diff()))
      const intervalId = setInterval(
        () => setTimeDiff(dayjs.duration(expDate.diff())),
        1000
      )
      return () => clearInterval(intervalId)
    },
    [expDate]
  )

  const countDown = useMemo(
    () => {
      
      if (!timeDiff || data?.state !== 'posted') return ''
      if (expDate.isAfter(dayjs.utc())) {
        return timeDiff.format('mm:ss')
      } else {
          return '00:00'
      }
    },
    [expDate, timeDiff, data?.state]
  )

  const titleColor = useMemo(
    () => {
      if (!data) return '#FFE500'
      if (data.state === 'correct') return '#0FAE6F'
      if (data.state === 'incorrect') return '#DD5E5E'
      return '#FFE500'
    },
    [data]
  )


  if (!data) return 
  
  const { title } = data

  return (
    <Box
      color={titleColor}
      fontSize="16px"
      fontWeight="700"
    >{title} {countDown}</Box>
    
  )
}

export default Title
