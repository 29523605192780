import { Flex } from '@chakra-ui/react'

const PrizesTabs = ({ index, onChange }) => (
  <Flex
    position="relative"
    bg="var(--chakra-colors-white10)"
    alignItems="stretch"
    height="37px"
    borderRadius="32px"
    mt="20px"
    w="270px"
    mx="auto"
    fontSize="14px"
    fontWeight="600"
  >
    {/* <Box 
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      borderRadius="50%"
      zIndex="100"
    /> */}
    <Flex
      onClick={() => onChange(0)}
      flex="1"
      alignItems="center"
      justifyContent="center"
      borderRadius="32px"
      bg={index === 0 ? 'var(--chakra-colors-white40)' : 'transparent'}
    >
      UPCOMING
    </Flex>
      
    <Flex 
      flex="1"
      onClick={() => onChange(1)}
      alignItems="center"
      justifyContent="center"
      borderRadius="32px"
      bg={index === 1 ? 'var(--chakra-colors-white40)' : 'transparent'}
    >
      CLAIMED
    </Flex>
  </Flex>
)

export default PrizesTabs