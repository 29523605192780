import { Box, Center, Button } from '@chakra-ui/react'
import Prize from 'components/prize/Prize'
import Confetti from 'react-confetti'
import { createPortal } from 'react-dom'

const WinState = ({ state }) => {
  const { title,  prize } = state
  return (
    <Box width="100%" mt="30px">
      <Center>
        <Box
          fontSize="32px"
          fontWeight="800"
          maxW="300px"
          textAlign="center"
          lineHeight="40px"
        >{title}</Box>
      </Center>
      <Prize prize={prize} mininal mt="30px" />
      <Center mt="40px">
        <Button width="200px">Claim your prize</Button>
      </Center>
      <ConfettiWrapper />
    </Box>
  )
}

export default WinState





const ConfettiWrapper = () => {
  return createPortal(
    <Box pointerEvents="none" position="absolte" top="0" left="0" bottom="0" right="0">
      <Confetti width={document.body.innerWidth} height={document.body.innerHeight} />
    </Box>,
    document.body
  )
}