import { Box, SimpleGrid, Flex } from '@chakra-ui/react'

const WaitingState = ({ userAnswers, state: { activeQuestion: { answers }}, is_active }) => {
  
  return (
    <Box width="100%" mt="30px">
      <SimpleGrid columns={answers.length <= 2 ? 1 : 2} spacing="10px" rowGap="10px">
        {answers.map(answer => {
          const selected = userAnswers.findIndex(ua => ua.answer === answer.id) !== -1
          
          return (
            <Flex 
              pos="relative"
              key={answer.id}
              h="52px"
              alignItems="center"
              justifyContent="center"
              bg={selected ? '#FFFFFF66' : "#FFFFFF0D"}
              borderRadius="32px"
              pos="relative"
              fontSize="14px"
              fontWeight="700"
              border={selected ? '2px solid #FFFFFF66' : '2px solid transparent'}
              color={selected  ? 'white' : "#FFFFFF66"}
            >
              {answer.text}
              <Flex 
                pos="absolute"
                right="0"
                top="0"
                transform="translateY(-50%)"
                bg="#141414"
                borderRadius="12px"
                color="white"
                fontSize="14px"
                fontWeight="700"
                h="21px"
                alignItems="center"
                justifyContent="center"
                px="8px"
              >{answer.selected_count}</Flex>
            </Flex>
          )
        })}
      </SimpleGrid>
    </Box>
  )
}

export default WaitingState