// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fugaz+One&family=Manrope:wght@200;300;400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  --chakra-fonts-body: 'Manrope', sans-serif;
  --chakra-colors-chakra-body-bg: var(--chakra-colors-bg); 
  --chakra-colors-chakra-body-text: var(--chakra-colors-white);
  --base-font-size: 16px;
  min-height: 100vh !important;
  transition: none !important;
}

.lose-state, .spectator-mode {
  background: linear-gradient(180deg, #3A3A3A 0%, #000000 100%);
}

.open-menu {
  overflow: hidden;
  background: black;
}

#root {
  max-width: 400px;
  margin: 0 auto;
}
#root a {
  color: var(--chakra-colors-green)
}

/* .hamburger-react {
  height: 28px;
  width: 28px;
} */`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAEA;EACE,0CAA0C;EAC1C,uDAAuD;EACvD,4DAA4D;EAC5D,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,6DAA6D;AAC/D;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE;AACF;;AAEA;;;GAGG","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fugaz+One&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');\n\nbody {\n  --chakra-fonts-body: 'Manrope', sans-serif;\n  --chakra-colors-chakra-body-bg: var(--chakra-colors-bg); \n  --chakra-colors-chakra-body-text: var(--chakra-colors-white);\n  --base-font-size: 16px;\n  min-height: 100vh !important;\n  transition: none !important;\n}\n\n.lose-state, .spectator-mode {\n  background: linear-gradient(180deg, #3A3A3A 0%, #000000 100%);\n}\n\n.open-menu {\n  overflow: hidden;\n  background: black;\n}\n\n#root {\n  max-width: 400px;\n  margin: 0 auto;\n}\n#root a {\n  color: var(--chakra-colors-green)\n}\n\n/* .hamburger-react {\n  height: 28px;\n  width: 28px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
