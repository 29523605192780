import { Flex } from '@chakra-ui/react'
import { ReactComponent as Icon } from 'icons/error.svg'

const Error = ({ children, ...props }) => (
  <Flex 
    color="#DD5E5E"
    fontSize="12px"
    fontWeight="600"
    bg="#FEF1F2"
    borderRadius="10px"
    py="5px"
    px="6px"
    alignItems="center"
    gap="5px"
    {...props}
  >
    <Icon />
    {children}
  </Flex>
)

export default Error