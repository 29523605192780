import { Box, List, ListItem, Text } from '@chakra-ui/react'

const Rules = ({ rules, ...other }) => {
  if (!rules) return null
  return (
    
    <Box {...other}>
      {Object.keys(rules).map(key => (
        <Box key={key} mt="30px">
          <Text
            fontSize="24px"
            fontWeight="800"
            lineHeight="30px"
            align="center"
            textTransform="uppercase"
          >{key.split('_').join(' ')}</Text>
          <List 
            listStyleType="disc"
            paddingLeft="20px"
            mt="20px"
            fontSize="16px"
            fontWeight="700"
          >
            {rules[key].map((rule, index) => (
              <ListItem 
                key={rule}
                mt={index > 0 ? '1em' : '0'}
              >{rule}</ListItem>
            ))}
          </List>
        </Box>
      ))}
    </Box>
  )
}

export default Rules