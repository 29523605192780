import { Box, Button, Flex, Img } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Confetti from 'react-confetti'


const Onboarding = ({ onClose }) => {
  const [index, setIndex] = useState(0)
  useEffect(
    () => {
      document.body.style.overflow = 'hidden';
      ['01.png', '02.png', '03.png'].forEach(src => {
        const img = new Image();
        img.src = `/images/${src}`;
      });
      return () => {
        document.body.style.overflow = '';
      }
    },
    []
  )
  return createPortal(
    <Box
      pos="fixed"
      top="48px"
      bottom="0"
      left="50%"
      transform="translateX(-50%)"
      maxW="400px"
      w="100%"
      zIndex="90"
      px="15px"
      py="20px"
      textAlign="center"
      color="white"
      fontWeight="800"
    >
      <Box fontSize="32px" lineHeight="40px">How to play</Box>
      <Box
        position="relative"
        h="330px"
        mt="15px"
      >
        {index === 2 && (
          <Box
            position="absolute"
            top="8%"
            left="10%"
            right="10%"
            bottom="0"
            borderTopRadius="40px"
            overflow="hidden"
          >
            <Confetti width={document.body.innerWidth} height={document.body.innerHeight} />
          </Box>
        )}
        <Img 
          src={`/images/0${index + 1}.png`}
          width="100%"
          height="100%"
          objectFit="contain"
        />
      
      </Box>
      <Box mt="20px">
        <Box fontSize="24px" lineHeight="30px" textTransform="uppercase">
          {index === 0 && <Box>Answer live<br />questions</Box>}
          {index === 1 && <Box>GET IT RIGHT &<br />MOVE ON</Box>}
          {index === 2 && <Box>Last one<br />standing wins</Box>}
        </Box>
      </Box>
      <Flex
        justifyContent="center"
        alignItems="center"
        gap="10px"
        mt="30px"
      >
        <Box w="10px" h="10px" bg="white" borderRadius="10px" opacity={index === 0 ? 1 : 0.4} />
        <Box w="10px" h="10px" bg="white" borderRadius="10px" opacity={index === 1 ? 1 : 0.4} />
        <Box w="10px" h="10px" bg="white" borderRadius="10px" opacity={index === 2 ? 1 : 0.4} />

        
      </Flex>
      <Flex justifyContent="center" mt="30px">
        <Button
          width="200px"
          onClick={() => {
            if (index === 2) {
              localStorage.setItem('onboarding', true)
              onClose()
            } else {
              setIndex(index + 1)
            }
          }}
        >{index > 1 ? 'Enter the lobby' : 'Next'}</Button>
      </Flex>
    </Box>,
    document.body
  )
}

export default Onboarding;