import { Input as ChakraInput } from '@chakra-ui/react'

const Input = props => (
  <ChakraInput
    bg="white"
    color="#141414"
    _placeholder={{
      color: '#14141499'
    }}
    {...props}
  />
)


export default Input