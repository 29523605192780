import { Box, SimpleGrid, Flex } from '@chakra-ui/react'

const IncorrectState = ({ state }) => {
  const {  activeQuestion: { id, answers }, userAnswers } = state
  const currentAnswer = userAnswers.find(a => a.question === id)
  const selected = currentAnswer && currentAnswer.answer
  const correctIds = answers.filter(a => a.is_right).map(a => a.id)
  
  return (
    <Box width="100%" mt="30px">
      <SimpleGrid columns={answers.length <= 2 ? 1 : 2} spacing="10px" rowGap="10px">
        {answers.map(answer => (
          <Flex 
            key={answer.id}
            h="52px"
            alignItems="center"
            justifyContent="center"
            bg={selected === answer.id ? '#DD5E5E' : (correctIds.includes(answer.id) ? '#0FAE6F' : "#FFFFFF1A")}
            borderRadius="32px"
            pos="relative"
            fontSize="14px"
            fontWeight="700"
            border={selected === answer.id ? '2px solid #FFFFFF66' : '2px solid transparent'}
            
          >
            {answer.text}
            <Flex 
              pos="absolute"
              right="0"
              top="0"
              transform="translateY(-50%)"
              bg="#141414"
              borderRadius="12px"
              color="white"
              fontSize="14px"
              fontWeight="700"
              h="21px"
              alignItems="center"
              justifyContent="center"
              px="8px"
            >{answer.selected_count}</Flex>
          </Flex>
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default IncorrectState